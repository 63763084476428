import { Link } from 'gatsby';
import React, { memo } from 'react';
import * as styles from './TopNavbar.module.css';
import Avatar from '../shared/Avatar';
// import Logo from '../shared/Logo';

const TopNavbar = () => {

  return (
    <div className={styles.navbar}>
      <div className="px-8">
        <Link to="/">
          <div className="flex">
          <img className="h-8 w-auto sm:h-10" alt="boardsi logo" src="https://cdn.boardsi.com/logos/boardsi/logo-dark.png"/>
          </div>
        </Link>

        <Avatar className="ml-8" />
      </div>
    </div>
  );

};
export default memo(TopNavbar);
